import React from 'react'

function About() {
    return (
        <div>
            <h1>About</h1>
            <p><em>Coming Soon</em></p>
        </div>
    )
}

export default About