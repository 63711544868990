import React from 'react'

function DevLogs() {
    return (
        <div>
            <h1>DevLogs</h1>
            <p><em>Coming Soon</em></p>
        </div>
    )
}

export default DevLogs