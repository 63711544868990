import React from 'react'

function Support() {
    return (
        <div>
            <h1>Support</h1>
            <p><em>Coming Soon</em></p>
        </div>
    )
}

export default Support