import React from 'react'

function Projects() {
    return (
        <div>
            <h1>Projects</h1>
            <p><em>Coming Soon</em></p>
        </div>
    )
}

export default Projects