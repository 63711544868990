import React from 'react'

function Account() {
  return (
    <div>
        <h1>Account</h1>
        <p><em>Sorry, this feature is currently disabled.</em></p>
    </div>
  )
}

export default Account