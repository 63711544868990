import React from 'react'
import { Link } from 'react-router-dom'

function Nav() {
    return (
        <nav>
            <Link to="/about">About Me</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/devlogs">DevLogs</Link>
            <Link to="/support">Support</Link>
            <Link to="/account" className='icon circle'><i class='ri-user-3-fill'></i></Link>
        </nav>
    )
}

export default Nav