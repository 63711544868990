import './App.scss';
import 'remixicon/fonts/remixicon.css';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Account from './pages/Account';
import Projects from './pages/Projects';
import DevLogs from './pages/DevLogs';
import Support from './pages/Support';
import About from './pages/About';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="devlogs" element={<DevLogs />} />
          <Route path="support" element={<Support />} />
          <Route path="account" element={<Account />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
